// import React, { useState } from "react";
// import { ProjectDeliveryFaq } from "./config";
// import Orderimg from "assets/orderimg.svg";
// import Arrowdown from "assets/arrowdown.svg";

// const ProjectDelivery = () => {
//   const [selected, setSelected] = useState(null);
//   const [open, setOpen] = useState(false);
//   const handleArrow = (id) => {
//     if (id === selected) {
//       setSelected(null);
//       setOpen(false);
//     } else {
//       setSelected(id);
//       setOpen(true);
//     }
//   };
//   return (
//     <div className=" px-4  sm:px-6 py-12 ">
//       <div className="max-w-6xl mx-auto  ">
//         <div>
//           <h1 className="text-center font-primary font-semibold text-4xl leading-[60px]">
//             Our Step-by-Step Process from Ordering to Project Delivery
//           </h1>
//           <div className="grid md:grid-cols-2 gap-8 mt-16 md:px-4">
//             <div className="flex items-center justify-center w-full  flex-col ">
//               {ProjectDeliveryFaq.map(({ imgUrl, heading, subheading, id }) => (
//                 <div
//                   className="card w-full border-b border-black flex justify-between mb-2 flex-col"
//                   key={id}
//                 >
//                   <div
//                     className="flex py-5   bg-white justify-between cursor-pointer"
//                     onClick={() => handleArrow(id)}
//                   >
//                     <div className="flex items-center">
//                       <img
//                         src={imgUrl}
//                         alt={heading}
//                         className="w-6 h-6 mr-4"
//                       />
//                       <h3
//                         className={`text-xl  font-bold font-secondary my-auto ${
//                           selected === id && open
//                             ? "text-[#2A7CF8]"
//                             : "text-[#00173A]"
//                         }`}
//                       >
//                         {heading}
//                       </h3>
//                     </div>
//                     <span
//                       className={`text-2xl text-black cursor-pointer transform transition-transform ${
//                         open && selected === id ? "rotate-180" : ""
//                       }`}
//                     >
//                       <img src={Arrowdown} alt="" />
//                     </span>
//                   </div>
//                   <div
//                     className={`transition-all ease-in-out delay-150 pb-4 duration-300 pr-4 md:pr-10 ${
//                       selected === id ? "block" : "hidden"
//                     }`}
//                   >
//                     {open ? (
//                       <p className="text-[#00173A] font-opensans leading-8 ">
//                         {subheading}
//                       </p>
//                     ) : null}
//                   </div>
//                 </div>
//               ))}
//             </div>

//             <div className="flex items-center">
//               <img src={Orderimg} alt="orderimage" />
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };
// export default ProjectDelivery;
import React, { useState } from "react";
import { ProjectDeliveryFaq } from "./config";
import Orderimg from "assets/orderimg.svg";
import Arrowdown from "assets/arrowdown.svg";

const ProjectDelivery = () => {
  // Initialize selected with the ID of the first item to be open by default
  const [selected, setSelected] = useState(ProjectDeliveryFaq[0].id);
  const [open, setOpen] = useState(true);

  const handleArrow = (id) => {
    if (id === selected) {
      setSelected(null);
      setOpen(false);
    } else {
      setSelected(id);
      setOpen(true);
    }
  };

  return (
    <div className="px-4 ">
      <div className="sm:px-6 py-12 max-w-6xl mx-auto">
        <div>
          <h1 className="text-center font-primary font-semibold text-4xl leading-[60px]">
            Our Step-by-Step Process from Ordering to Project Delivery
          </h1>
          <div className="grid lg:grid-cols-2 gap-8 mt-16 md:px-4">
            <div className="flex items-center justify-center w-full flex-col">
              {ProjectDeliveryFaq.map(({ imgUrl, heading, subheading, id }) => (
                <div
                  className="card w-full border-b border-black flex justify-between mb-2 flex-col"
                  key={id}
                >
                  <div
                    className="flex py-5 bg-white justify-between cursor-pointer"
                    onClick={() => handleArrow(id)}
                  >
                    <div className="flex items-center">
                      <img
                        src={imgUrl}
                        alt={heading}
                        className="w-6 h-6 mr-4"
                      />
                      <h3
                        className={`text-xl font-bold font-secondary font-primary my-auto ${
                          selected === id && open
                            ? "text-[#2A7CF8]"
                            : "text-[#00173A]"
                        }`}
                      >
                        {heading}
                      </h3>
                    </div>
                    <span
                      className={`text-2xl text-black cursor-pointer transform transition-transform ${
                        open && selected === id ? "rotate-180" : ""
                      }`}
                    >
                      <img src={Arrowdown} alt="" />
                    </span>
                  </div>
                  <div
                    className={`transition-all ease-in-out delay-150 pb-4 duration-300 pr-4 md:pr-10 ${
                      selected === id ? "block" : "hidden"
                    }`}
                  >
                    {open ? (
                      <p className="text-[#00173A] font-opensans leading-8">
                        {subheading}
                      </p>
                    ) : null}
                  </div>
                </div>
              ))}
            </div>

            <div className="flex items-center justify-center">
              <img
                src={Orderimg}
                alt="orderimage"
                className="w-full lg:w-max"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default ProjectDelivery;
