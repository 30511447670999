import React from "react";
import Cta from "assets/ctaimg.png";
import { Link } from "gatsby";

const NextProject = ({ heading, subheading, button }) => {
  const backgroundImageStyle = {
    backgroundImage: `url(${Cta})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
    height: "500px",
  };

  return (
    <div className="my-12  sm:px-6 py-12 ">
      <div
        className="max-w-7xl mx-auto rounded-lg"
        style={backgroundImageStyle}
      >
        <div className="flex items-center ">
          <div className="text-white mt-16 sm:mt-40 ml-4 lg:ml-14 md:ml-20 lg:mt-36  text-center md:text-left  lg::max-w-2xl">
            <h1 className="text-3xl md:text-4xl font-primary font-semibold leading-[54px] ">
              {heading}
            </h1>
            <p className="mt-6 text-base md:text-lg leading-7 font-normal font-primary px-6 md:px-0 ">
              {subheading}
            </p>
            <div className="mt-6 w-full lg:w-max font-opensans  px-6 md:px-0">
              <Link to="/contactus.php">{button}</Link>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NextProject;
