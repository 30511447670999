import React from "react";
import { Guranteeworklist } from "./config";

const GuaranteeWork = () => {
  return (
    <div className="w-full bg-[#E7F0FF] px-4">
      <div className="   sm:px-6 py-12 ">
        <div className="max-w-5xl mx-auto  ">
          <div className="text-[#00173A]">
            <h1 className="font-semibold font-primary text-3xl md:text-4xl leading-[60px] text-center">
              How Our Guarantee Works - 3 Simple Steps
            </h1>

            <p className="text-center py-8 text-base md:text-lg font-normal leading-6 font-opensans">
              We want you to avoid jumping through hoops if there's an issue.
              Just let us know. From there, we handle everything, from
              assessment to resolution. Your focus should be on your business;
              let us handle your language needs.
            </p>
          </div>
        </div>
        <div className="max-w-6xl  mx-auto  pb-6 mt-6">
          <div className="lg:flex flex-wrap items-center justify-center gap-6">
            {Guranteeworklist.map((item, i) => (
              <div className="flex items-center justify-center">
                <div className="bg-[#00173A] w-[345px]  text-white p-6 rounded-lg flex flex-col gap-4 lg:my-0 my-4">
                  <h1 className="text-[#2A7CF8] font-semibold text-lg leading-5">
                    {item.step}
                  </h1>
                  <h2 className="text-2xl font-medium font-primary">
                    {item.heading}
                  </h2>
                  <p className="font-normal text-base md:text-lg font-opensans leading-6">
                    {item.subheading}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
export default GuaranteeWork;
