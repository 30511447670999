import React from "react";
import Investimg from "assets/investimg.svg";
const Invest = () => {
  return (
    <div className="py-16 px-4 ">
      <div className=" pt-5 sm:px-6 py-12">
        <div className="max-w-5xl mx-auto  ">
          <div className="grid lg:grid-cols-2 items-center justify-center gap-4">
            <div className="text-[#00173A] bg-[#2A7CF8] px-12 py-16 rounded-lg flex flex-col items-center gap-6">
              <h1 className="font-primary font-semibold text-3xl  md:text-4xl leading-[60px]">
                Invest in Peace of Mind for Your Business
              </h1>
              <p className="font-primary font-medium text-2xl leading-9">
                We Get Your Concerns, and We're Here for You
              </p>
            </div>
            <div>
              <img src={Investimg} alt="" className="w-full lg:w-max" />
            </div>
          </div>
          <p className=" mt-12 text-center text-base md:text-lg font-normal leading-6 font-opensans text-[#00173A] px-4">
            As a business owner, every investment comes with its concerns.
            Quality, cost, delivery timelines—you name it. At Tomedes, we've
            built our services around these very concerns. Our aim? A lasting
            partnership where you feel heard, valued, and reassured. With our
            1-Year Quality Guarantee, you can rest easy knowing we've got your
            back.
          </p>
        </div>
      </div>
    </div>
  );
};
export default Invest;
