import React from "react";
import { Qualityassurancelist, Qualitytransparencylist } from "./config";

const GuaranteeCover = () => {
  return (
    <div className="w-full bg-[#F5F9FF] mt-10 px-4 ">
      <div className=" sm:px-6 py-12">
        <div className="max-w-4xl mx-auto  ">
          <div>
            <h1 className="text-center font-primary font-semibold text-3xl md:text-4xl leading-[60px] text-[#00173A]">
              What Does Our Guarantee Cover?
            </h1>
            <p className="text-center text-base  md:text-lg py-8 font-opensans leading-6 font-normal text-[#00173A]">
              You've got enough on your plate without wading through complicated
              terms. Our 1-Year Quality Guarantee is straightforward: accuracy,
              linguistic quality, and sticking to your project's specifics. And
              while we aim for perfection from the get-go, if there are
              concerns, we're here to make them right.
            </p>
          </div>
        </div>
        <div className="max-w-5xl mx-auto px-4 pt-12 bg-[#00173A] text-white pb-12 rounded-lg mt-12">
          <div className="lg:flex  gap-16">
            <div className=" text-2xl leading-9 font-primary text-white font-semibold lg:w-1/3 text-center lg:text-left ">
              <h2 className="lg:w-40">Quality Assurances</h2>
              {/* <h1>Assurances</h1> */}
            </div>
            <div className=" flex flex-col gap-16 px-8 lg:w-2/3 mt-12 lg:mt-0">
              {Qualityassurancelist.map((item, i) => (
                <div className=" flex flex-col gap-4 ">
                  <div className="flex gap-2">
                    <img src={item.imgUrl} alt="" />
                    <h2 className="font-primary leading-8 text-xl font-medium">
                      {item.heading}
                    </h2>
                  </div>
                  <p className="font-normal text-lg leading-6 font-opensans">
                    {item.subheading}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
        <div className="max-w-5xl mx-auto px-4 pt-12 bg-[#E7F0FF] text-[#00173A] pb-12 rounded-lg mt-8">
          <div className="lg:flex  gap-16">
            <div className="text-2xl leading-9 font-primary  font-semibold lg:w-1/3 text-center lg:text-left ">
              <h1 className="lg:w-40">For full transparency</h1>
            </div>
            <div className="flex flex-col gap-16 px-8 lg:w-2/3 mt-12 lg:mt-0">
              {Qualitytransparencylist.map((item, i) => (
                <div className=" flex flex-col gap-4">
                  <div className="flex gap-2">
                    <h2 className="font-primary leading-8 text-xl font-medium">
                      {item.heading}
                    </h2>
                  </div>
                  <p className="font-normal text-lg leading-6 font-opensans">
                    {item.subheading}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default GuaranteeCover;
