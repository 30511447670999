import React from "react";
import QualityGurantee from "./quality-gurantee";
import { Link } from "gatsby";
import GuranteeProject from "./guaranteeProject";
import ProjectDelivery from "./projectDelivery";
import GuaranteeCover from "./guranteeCover";
import GuaranteeWork from "./guaranteeWork";
import Invest from "./invest";
import Advocate from "./advocate";
import ClientTestimonial from "./clientTestimonial";
import NextProject from "./nextproject";
import Hero from "assets/heroImage.png";
import { ClientTestimoniallist } from "./config";

const OneYearGuarantee = () => {
  const backgroundImageStyle = {
    backgroundImage: `url(${Hero})`,
    backgroundSize: "cover",
    backgroundPosition: "center",
  };
  const buttonContent = (
    <button className="border border-[#ffffff] px-4 py-2 font-opensans  rounded-sm w-full md:w-max ">
      Contact us
    </button>
  );

  const nextbutton = (
    <button className="bg-[#ff7b16] px-4 py-2 rounded-md w-full md:w-max">
      Contact Us
    </button>
  );
  return (
    <>
      <section
        className="px-4  py-12 lg:h-[600px] flex items-center"
        style={backgroundImageStyle}
      >
        <QualityGurantee
          heading=" 1-Year Quality Guarantee by Tomedes"
          subheading="Every business decision you make matters. When it comes to language
          services, you deserve more than just words. At Tomedes, we stand
          behind every project, making sure you get the value you expect, sealed
          with our 1-Year Quality Guarantee."
          button={buttonContent}
        />
      </section>
      <div className="w-11/12 max-w-7xl mx-auto flex flex-col">
        <div className="hidden lg:block w-full px-4 2xl:px-0 py-7 ">
          <div className="flex max-w-7xl mx-auto gap-3 items-center text-base text-[#494949] font-opensans font-semibold">
            <Link to="/">
              <img
                src="https://tomedes.gumlet.io/S3UploadedFiles/1666089711home.svg"
                alt="home"
              />
            </Link>
            <img
              src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
              alt="navigate"
            />
            <Link to="/about-tomedes">
              <span>About</span>
            </Link>
            <img
              src="https://tomedes.gumlet.io/S3UploadedFiles/1666089692greater-than.svg"
              alt="navigate"
            />
            <span>1 Year Guarantee</span>
          </div>
        </div>
      </div>
      <GuranteeProject />
      <ProjectDelivery />
      <GuaranteeCover />
      <GuaranteeWork />
      <Invest />
      <div className="bg-[#FFEFE2] w-full">
        <Advocate
          heading="We Offer More Than Just a Service, We're Your Advocates"
          subheading=" Trust isn't given; it's earned. At Tomedes, every project is a
            chance to earn that trust. Beyond delivering top-notch language
            services, we're here to champion your needs. We want to be your
            go-to experts, delighting you at every turn. Why? Because your
            success is ours, too."
        />
      </div>
      <ClientTestimonial
        text="Client Testimonials"
        description=" Discover what our clients have to say about the reliability and
              responsiveness of our customer support. Their stories speak to the
              immense value of having a dedicated support system."
        btntext="See Testimonials"
        testimoniladata={ClientTestimoniallist}
        bgClass="bg-white"
      />
      <div className="px-4">
        <NextProject
          heading="Start your next project with confidence"
          subheading="Lock in your 1-Year Quality Guarantee today."
          button={nextbutton}
        />
      </div>
    </>
  );
};
export default OneYearGuarantee;
